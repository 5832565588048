import React, { useCallback, useEffect, useRef, useState } from 'react';
import { currencyUtils, localStorageHelper } from 'utils';
import { Loader } from 'Components/Loader/Loader';
import { Overlay } from 'Components/Overlay/Overlay';
import { CloseCircleOutlined } from '@ant-design/icons/lib';
import { GAME_HISTORY_URL } from '../../../utils/config';
import { useDispatch, useSelector } from 'react-redux';
import { getGameConfigurationsByUser } from '../../../redux/selectors/user-selectors';
import { getAllGameConfigurationsByUser } from '../../../redux/actions/user-actions';

enum RoundType {
    Paid = 'Paid',
    Bought = 'Bought',
    Free = 'Free',
}

interface GameHistoryProps {
    data: any;
    summaryData: any;
    currencyId: number;
    onClose: () => void;
    t: (key: string) => string;
    error: string;
    player: any;
}

const GameHistory: React.FC<GameHistoryProps> = ({
    data,
    summaryData,
    currencyId,
    onClose,
    t,
    error,
    player,
}) => {
    const dispatch = useDispatch();
    const games: any[] = useSelector(getGameConfigurationsByUser);
    const gameData = games.find((game: any) => game.id === player.gameId);
    const [loading, setLoading] = useState<boolean>(true);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const resizeFrame = useCallback(() => {
        if (iframeRef.current) {
            setTimeout(resizeFrame, 100);
        }
    }, []); // Empty dependency array ensures this function does not change on every render

    useEffect(() => {
        const handleIframeLoad = () => {
            const { gameCode } = summaryData;
            const gameHistoryData = {
                game: gameData,
                currency: currencyUtils.getCurrencyDataById(currencyId),
                betSession: data,
                assetsPath: `${GAME_HISTORY_URL}/${gameCode}/latest`,
                summaryData,
                bought: summaryData.type === RoundType.Bought,
                bet: summaryData.bet,
            };

            iframeRef.current?.contentWindow?.postMessage(
                JSON.parse(JSON.stringify(gameHistoryData)),
                '*',
            );

            resizeFrame();
            setLoading(false);
        };

        const iframeElement = iframeRef.current; // Store ref value inside a variable

        if (iframeElement) {
            iframeElement.onload = handleIframeLoad;
        }

        return () => {
            if (iframeElement) {
                iframeElement.onload = null;
            }
        };
    }, [summaryData, currencyId, data, gameData, resizeFrame]);

    useEffect(() => {
        dispatch(getAllGameConfigurationsByUser());
    }, [dispatch]);

    const gameHistoryLanguage =
        localStorageHelper.getChainedValue('user.preferences.gh_language') || 'en';

    return (
        <>
            <div id="gameHistory">
                {error ? (
                    <div className="error">{error}</div>
                ) : (
                    <>
                        <iframe
                            title={t('players_report_game_history')}
                            className={loading ? '' : 'ready'}
                            src={`${GAME_HISTORY_URL}/${summaryData.gameCode}/latest/index.html?lang=${gameHistoryLanguage}`}
                            ref={iframeRef}
                        />
                        {loading ? (
                            <Loader />
                        ) : (
                            <CloseCircleOutlined className="closeBtn" onClick={onClose} />
                        )}
                    </>
                )}
            </div>
            <Overlay isVisible switchState={onClose} />
        </>
    );
};

export default GameHistory;
