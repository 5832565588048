import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Modal, Space, Table, Tag, Tooltip } from 'antd';
import { ExclamationCircleOutlined, InfoOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import {
    deleteCampaign,
    terminateCampaign,
} from '../../../../redux/actions/backoffice/promotions-actions';

const { confirm } = Modal;

const getItems = (
    record: any,
    canUserManageCampaigns: boolean,
    requestPromotions: Function,
    dispatch: any,
    t: any,
) => {
    const actions = [];

    if (canUserManageCampaigns) {
        actions.push({
            key: 'edit',
            label: <Link to={`/casino/awarded-promotion/${record.id}/edit`}>Edit</Link>,
        });
    }

    const showConfirm = (title: string, content: any, onOk: any) => {
        confirm({
            title,
            icon: <ExclamationCircleOutlined />,
            content,
            okText: t('yes'),
            okType: 'danger',
            onOk,
            onCancel() {},
        });
    };

    if (record.status === 'UPCOMING') {
        actions.push({
            key: 'remove',
            label: t('remove_campaign'),
            onClick: () =>
                showConfirm(t('do_you_want_to_remove_this_campaign'), null, () => {
                    dispatch(deleteCampaign(record.id));
                    setTimeout(() => {
                        requestPromotions();
                    }, 1000);
                }),
        });
    }

    if (record.status === 'ONGOING') {
        actions.push({
            key: 'terminate',
            label: t('terminate_campaign'),
            onClick: () =>
                showConfirm(
                    t('do_you_want_to_terminate_this_campaign'),
                    t('after_terminating_the_campaign'),
                    () => {
                        dispatch(terminateCampaign({ id: record.id, force: true }));
                        setTimeout(() => {
                            requestPromotions();
                        }, 1000);
                    },
                ),
        });
    }

    actions.push({
        key: 'clone',
        label: <Link to={`/casino/awarded-promotion/clone/${record.id}`}>Clone</Link>,
    });

    return actions;
};

const PromotionsColumns = (
    canUserManageCampaigns: boolean,
    requestPromotions: Function,
    dispatch: any,
) => {
    const { t } = useTranslation();

    return [
        {
            title: t('timezone'),
            dataIndex: 'timezone',
            key: 'timezone',
            width: 165,
        },
        {
            title: t('startAt'),
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            width: 165,
        },
        {
            title: t('endAt'),
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
            width: 165,
        },
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            width: 165,
        },
        {
            title: t('reference'),
            dataIndex: 'externalCampaignId',
            key: 'externalCampaignId',
            sorter: true,
            width: 165,
        },
        Table.EXPAND_COLUMN,
        {
            title: t('games'),
            dataIndex: 'gamesDisplay',
            key: 'games',
            width: 165,
        },
        {
            title: t('currencyCode'),
            dataIndex: 'currencyCode',
            key: 'currencyCode',
            width: 165,
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 165,
            render: (text: any, record: any) => {
                const colorToStatus: any = {
                    DISABLED: 'red',
                    ONGOING: 'green',
                    UPCOMING: 'blue',
                    ENDED: 'gold',
                };

                return (
                    <Tag color={colorToStatus[record.status]}>{t(record.status.toLowerCase())}</Tag>
                );
            },
        },
        {
            title: (
                <div style={{ display: 'flex' }}>
                    {t('playing/eligible_player')}{' '}
                    <Tooltip
                        title={t(
                            'for_the_players_list_type_only_playing_players_number_is_reflected',
                        )}
                    >
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'eligiblePlayersNumber',
            key: 'eligiblePlayersNumber',
            width: 165,
            render: (text: any, record: any) => {
                return `${Number(record.participatingPlayersNumber) || 0}${
                    record.strategy !== 'PLAYER_LIST'
                        ? `/${Number(record.eligiblePlayersNumber)}`
                        : ''
                }`;
            },
        },
        {
            title: t('opt_out_players'),
            dataIndex: 'optOutPlayersNumber',
            key: 'optOutPlayersNumber',
            width: 165,
            render: (optOutPlayersNumber: number, record: any) => {
                return `${Number(optOutPlayersNumber)}${record.strategy !== 'FIRST_N_PLAYERS' ? `/${Number(record.eligiblePlayersNumber)}` : ''}`;
            },
        },
        {
            title: t('strategy'),
            dataIndex: 'strategy',
            key: 'strategy',
            width: 165,
        },
        {
            title: (
                <div style={{ display: 'flex' }}>
                    Features Played / Given{' '}
                    <Tooltip
                        title={t(
                            'for_the_players_list_type_only_the_number_of_played_rounds_is_reflected',
                        )}
                    >
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'roundsPlayed',
            key: 'roundsPlayed',
            width: 165,
            render: (text: any, record: any) => {
                return `${Number(record.roundsPlayed)}${
                    record.strategy !== 'PLAYER_LIST'
                        ? `/${record.betNumber * record.eligiblePlayersNumber}`
                        : ''
                }`;
            },
        },
        {
            title: t('actions'),
            key: 'action',
            fixed: 'right',
            width: 135,
            render: (_: any, record: any) => (
                <Space size="large">
                    <Link
                        className="text-red-600 hover:text-red-300"
                        to={`/casino/awarded-promotion/${record.id}`}
                    >
                        View
                    </Link>
                    {record.status === 'ENDED' ? (
                        <Link
                            className="text-red-600 hover:text-red-300"
                            to={`/casino/awarded-promotion/clone/${record.id}`}
                        >
                            Clone
                        </Link>
                    ) : (
                        <Dropdown
                            menu={{
                                items: getItems(
                                    record,
                                    canUserManageCampaigns,
                                    requestPromotions,
                                    dispatch,
                                    t,
                                ),
                            }}
                        >
                            <a>
                                More <DownOutlined />
                            </a>
                        </Dropdown>
                    )}
                </Space>
            ),
        },
    ];
};

export default PromotionsColumns;
