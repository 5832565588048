import { localStorageHelper } from '../../../../../utils';

import * as Yup from 'yup';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface PromotionLimits {
    maxCampaignDurationHours: number;
    maxCampaignEndDateDays: number;
    maxFreeSpinsPerPlayer: number;
    maxParticipantFileUploadLimitMb: number;
    maxParticipantFileUploadLimitRows: number;
    maxPlayersPerFirstNPlayersCampaign: number;
    maxPlayersPerPlayerListCampaign: number;
}

const defaultPromotionLimits: PromotionLimits = {
    maxCampaignDurationHours: 8784,
    maxCampaignEndDateDays: 366,
    maxFreeSpinsPerPlayer: 100,
    maxParticipantFileUploadLimitMb: 5,
    maxParticipantFileUploadLimitRows: 20000,
    maxPlayersPerFirstNPlayersCampaign: 3000,
    maxPlayersPerPlayerListCampaign: 20000,
};

const promotionLimits: any =
    localStorageHelper.getChainedValue('user.brandFeatures.promotionLimits') ||
    defaultPromotionLimits;

export const validationSchema = (t: TFunction) =>
    Yup.object().shape({
        campaign_name: Yup.string()
            .trim('name_should_not_start_or_end_with_a_space')
            .strict(true)
            .required(t('campaign_name_is_required')),
        start_at: Yup.date()
            .required(t('start_date_is_required'))
            .test('is-valid-date', t('start_date_is_invalid'), (value) => {
                return value && dayjs(value).isValid();
            })
            .test('is-future-date', t('start_date_must_be_in_the_future'), function (value) {
                const { timezone } = this.parent;
                if (!value) return true;

                const selectedDate = dayjs(value).tz(timezone, true);
                const now = dayjs().tz(timezone);
                return selectedDate.isAfter(now);
            }),
        end_at: Yup.date()
            .required(t('end_date_is_required'))
            .test(
                'is-valid-date',
                t('end_date_is_invalid'),
                (value) => value && dayjs(value).isValid(),
            )
            .test('is-greater', t('end_date_should_be_later_than_start_date'), function (value) {
                const { start_at, timezone } = this.parent;
                if (!start_at || !value) return true;
                const startDate = dayjs(start_at).tz(timezone, true);
                const endDate = dayjs(value).tz(timezone, true);
                return endDate.isAfter(startDate.add(1, 'hour'));
            })
            .test(
                'within-max-hours-limit',
                t('end_date_exceeds_max_hours_limit'),
                function (value) {
                    const { start_at, timezone } = this.parent;
                    if (!start_at || !value) return true;
                    const startDate = dayjs(start_at).tz(timezone, true);
                    const endDate = dayjs(value).tz(timezone, true);
                    return endDate.isBefore(
                        startDate.add(promotionLimits.maxCampaignDurationHours, 'hour'),
                    );
                },
            )
            .test('within-max-days-limit', t('end_date_exceeds_max_days_limit'), function (value) {
                const { start_at, timezone } = this.parent;
                if (!start_at || !value) return true;
                const startDate = dayjs(start_at).tz(timezone, true);
                const endDate = dayjs(value).tz(timezone, true);
                return endDate.isBefore(
                    startDate.add(promotionLimits.maxCampaignEndDateDays, 'day'),
                );
            }),
        start_event: Yup.string().required(t('start_event_is_required')),
        number_of_players: Yup.mixed().when('strategy', {
            is: 'PLAYER_LIST',
            then: () => Yup.mixed().nullable(),
            otherwise: () =>
                Yup.number()
                    .required(t('number_of_players_is_required'))
                    .positive(t('number_of_players_must_be_positive'))
                    .max(
                        promotionLimits.maxPlayersPerFirstNPlayersCampaign,
                        t('number_of_players_exceeds_limit'),
                    ),
        }),
        strategy: Yup.string()
            .required(t('strategy_is_required'))
            .oneOf(['FIRST_N_PLAYERS', 'PLAYER_LIST'], t('invalid_strategy')),
        valid_for: Yup.number()
            .required(t('valid_for_is_required'))
            .positive(t('valid_for_should_be_positive'))
            .max(promotionLimits.maxCampaignEndDateDays, t('valid_for_exceeds_max_days_limit')),
        currency: Yup.string().required(t('currency_is_required')),
        price: Yup.number()
            .nullable()
            .required(t('price_is_required'))
            .positive(t('price_must_be_positive')),
        number_of_features: Yup.number()
            .required(t('number_of_features_is_required'))
            .positive(t('number_of_features_must_be_positive'))
            .max(promotionLimits.maxFreeSpinsPerPlayer, t('number_of_features_exceeds_max_limit')),
        gameIds: Yup.array()
            .of(
                Yup.number()
                    .required(t('game_id_is_required'))
                    .positive(t('game_id_must_be_positive')),
            )
            .min(1, t('at_least_one_game_is_required')),
        rules_url: Yup.string()
            .nullable()
            .notRequired()
            .test('is-valid-url', t('invalid_url'), (value) => {
                if (!value) return true;
                const urlRegex = new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i);
                return urlRegex.test(value);
            })
            .test('no-double-slash', t('url_contains_double_slashes'), (value) => {
                if (!value) return true;
                const path = value?.split('://')[1] || value;
                return !path.includes('//');
            }),
        timezone: Yup.string().required(t('timezone_is_required')),
        allowPostExpirationCompletion: Yup.boolean(),
    });
