import React, { useCallback, useEffect, useState } from 'react';
import { getLoginRequestHeader, Messages } from '../../../../../utils';
import { Button, Divider, Input, Modal, Select, Table, TableProps, Upload } from 'antd';
import {
    DeleteOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    TeamOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAwardedCampaignPlayersData,
    getAwardedCampaignPlayersTotalData,
    shouldUpdateData,
} from '../../../../../redux/selectors/backoffice/propmotions-selectors';
import {
    addPlayerToCampaign,
    getAwardedCampaignPlayers,
    importPlayersCsv,
    removePlayersFromCampaign,
} from '../../../../../redux/actions/backoffice/promotions-actions';
import PlayersTableColumns from './PlayersTableColumns';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { IAwardedCampaignProps } from '../../NewAwardedPromotion';
import { SERVER_URL } from '../../../../../utils/config';

type SortOrder = 'DESCENDING' | 'ASCENDING';

interface SortConfig {
    sortKey: string;
    sortOrder: SortOrder;
}

interface IProps {
    campaignData: IAwardedCampaignProps;
    isViewMode: boolean;
    setIsViewMode: (isViewMode: boolean) => void;
}

const DEFAULT_SORT_CONFIG: SortConfig = { sortKey: 'id', sortOrder: 'DESCENDING' };
const DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE = 20;
const DEFAULT_PAGINATION = { pageNumber: 1, pageSize: DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE };

export const handleExportPlayersCsv = async (campaignId: number) => {
    const config = {
        method: 'GET',
        headers: {
            ...getLoginRequestHeader('application/json'),
            'Content-Type': 'application/json',
        },
    };

    const response = await fetch(
        `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant/csv`,
        config,
    );
    const blob = await response.blob();
    if (response.status === 200) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.csv';
        a.click();
    }
};

const PlayersTab = ({ campaignData, isViewMode }: IProps) => {
    const players = useSelector(getAwardedCampaignPlayersData);
    const playersTotal = useSelector(getAwardedCampaignPlayersTotalData);
    const shouldUpdate = useSelector(shouldUpdateData);
    const dispatch = useDispatch();
    const isPlayerList = campaignData?.strategy === 'PLAYER_LIST';
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isAddPlayerModalVisible, setIsAddPlayerModalVisible] = useState(false);
    const [newPlayerId, setNewPlayerId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationData, setPaginationData] = useState(DEFAULT_PAGINATION);
    const [sortConfig, setSortConfig] = useState<SortConfig>(DEFAULT_SORT_CONFIG);
    const [playersStatus, setPlayersStatus] = useState<string>('QUEUED');

    const [confirmModal, setConfirmModal] = useState<{
        visible: boolean;
        title: string;
        content: string;
        onConfirm: () => void;
    }>({ visible: false, title: '', content: '', onConfirm: () => {} });

    const handleConfirm = () => {
        confirmModal.onConfirm();
        setConfirmModal({ ...confirmModal, visible: false });
    };

    const uploadProps = {
        name: 'file',
        accept: '.csv',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file: File) => {
            const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');
            if (!isCSV) {
                Messages.error('Invalid file type. Please upload a CSV file.');
                return false;
            }
            setConfirmModal({
                visible: true,
                title: 'Confirm CSV Import',
                content: `Are you sure you want to import the file: ${file.name}?`,
                onConfirm: () => {
                    dispatch(importPlayersCsv({ file, campaignId: campaignData.id }));
                },
            });
            return false;
        },
    };

    const handleRemoveSelectedPlayers = () => {
        setConfirmModal({
            visible: true,
            title: 'Confirm Removal',
            content: `Are you sure you want to remove ${selectedRowKeys.length} selected players?`,
            onConfirm: () => {
                dispatch(
                    removePlayersFromCampaign({
                        campaignId: campaignData.id,
                        playerIds: selectedRowKeys,
                    }),
                );
                setSelectedRowKeys([]);
            },
        });
    };

    const rowSelection: TableProps<any>['rowSelection'] = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
        type: 'checkbox',
    };

    const handleAddPlayer = () => {
        if (!newPlayerId.trim()) {
            return;
        }
        setConfirmModal({
            visible: true,
            title: 'Confirm Add Player',
            content: `Are you sure you want to add Player ID: ${newPlayerId}?`,
            onConfirm: () => {
                dispatch(
                    addPlayerToCampaign({ playerId: newPlayerId, campaignId: campaignData.id }),
                );
                setIsAddPlayerModalVisible(false);
                setNewPlayerId('');
            },
        });
    };

    const handleSearch = (value: string) => {
        setSearchTerm(value);
    };

    const fetchPlayers = useCallback(() => {
        dispatch(
            getAwardedCampaignPlayers({
                campaignId: campaignData.id,
                paginationData,
                sortKey: sortConfig.sortKey,
                sortOrder: sortConfig.sortOrder,
                playerIdSearchValue: searchTerm || null,
                playersStatus,
                currency: 'USD',
            }),
        );
    }, [
        dispatch,
        campaignData.id,
        paginationData,
        sortConfig.sortKey,
        sortConfig.sortOrder,
        searchTerm,
        playersStatus,
    ]);

    useEffect(() => {
        fetchPlayers();
    }, [fetchPlayers, paginationData, sortConfig, searchTerm, playersStatus]);

    useEffect(() => {
        if (shouldUpdate) fetchPlayers();
    }, [fetchPlayers, shouldUpdate]);

    const handleExport = () => {
        campaignData.id && handleExportPlayersCsv(campaignData.id);
    };

    const columns = PlayersTableColumns('USD');

    const handleStatusChange = (status: string) => {
        setPlayersStatus(status);
        setPaginationData({ ...paginationData, pageNumber: 1 });
    };

    const handlePagination = (page: number, pageSize?: number) => {
        setPaginationData({ pageNumber: page, pageSize: pageSize || DEFAULT_PAGINATION.pageSize });
    };

    const handleSort = (sorter: SorterResult<any> | SorterResult<any>[]) => {
        if (Array.isArray(sorter) && sorter.length && sorter[0].order) {
            setSortConfig({
                sortKey: sorter[0].field?.toString() || 'id',
                sortOrder: sorter[0].order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
            });
        } else if (!Array.isArray(sorter) && sorter.field && sorter.order) {
            setSortConfig({
                sortKey: sorter.field.toString(),
                sortOrder: sorter.order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
            });
        }
    };

    const onTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        handlePagination(pagination.current ?? 1, pagination.pageSize);
        handleSort(sorter);
    };

    return (
        <>
            <Modal
                title={confirmModal.title}
                open={confirmModal.visible}
                onCancel={() => setConfirmModal({ ...confirmModal, visible: false })}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => setConfirmModal({ ...confirmModal, visible: false })}
                    >
                        Cancel
                    </Button>,
                    <Button key="confirm" type="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>,
                ]}
            >
                <p>{confirmModal.content}</p>
            </Modal>
            {!isViewMode && isPlayerList && (
                <>
                    <Modal
                        title="Add Player"
                        open={isAddPlayerModalVisible}
                        onCancel={() => {
                            setNewPlayerId('');
                            setIsAddPlayerModalVisible(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setNewPlayerId('');
                                    setIsAddPlayerModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" onClick={handleAddPlayer}>
                                Add Player
                            </Button>,
                        ]}
                    >
                        <label htmlFor="playerId">Player ID</label>
                        <Input
                            id="playerId"
                            size="large"
                            value={newPlayerId}
                            onChange={(e) => setNewPlayerId(e.target.value)}
                            placeholder="Enter Player ID"
                        />
                    </Modal>
                    {!isViewMode && players?.length === 0 && isPlayerList && (
                        <>
                            <Upload.Dragger {...uploadProps} className="flex mt-4">
                                <div className="flex w-full items-center justify-center mt-6">
                                    <div className="flex items-center justify-center w-16 h-16 rounded-full bg-gray-200">
                                        <TeamOutlined className="text-3xl text-gray-500" />
                                    </div>
                                </div>

                                <div className="text-center">
                                    <p className="text-lg font-semibold text-gray-700">
                                        No players yet
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Drag & drop your CSV file here, or click to browse local
                                        files
                                    </p>
                                </div>

                                <div className="flex w-full items-center justify-center mt-4 mb-6">
                                    <Button icon={<UploadOutlined />} color="black" className="">
                                        Import .CSV file
                                    </Button>
                                </div>
                            </Upload.Dragger>
                            <Button
                                onClick={() => setIsAddPlayerModalVisible(true)}
                                icon={<PlusOutlined />}
                                className="mt-4"
                            >
                                Or Add Players Manually
                            </Button>
                        </>
                    )}
                </>
            )}
            <div className="mt-6">
                <div className="flex w-full justify-between">
                    <Select
                        defaultValue="QUEUED"
                        className="w-52 mr-2"
                        onChange={handleStatusChange}
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'QUEUED', label: 'Queued' },
                            { value: 'IN_USE', label: 'In Use' },
                            { value: 'OPT_OUT', label: 'Opted Out' },
                            { value: 'COMPLETED', label: 'Completed' },
                            { value: 'EXPIRED', label: 'Expired' },
                        ]}
                    />
                    <Search
                        className="grow"
                        placeholder="Search players"
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchTerm}
                        allowClear
                    />
                    <div className="flex items-center justify-end ml-2">
                        {selectedRowKeys.length > 0 && !isViewMode && (
                            <Button
                                onClick={handleRemoveSelectedPlayers}
                                className="mr-2"
                                icon={<DeleteOutlined />}
                            >
                                Remove Selected
                            </Button>
                        )}
                        {!isViewMode && (
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />} color="black" className="mr-2">
                                    Import .CSV file
                                </Button>
                            </Upload>
                        )}

                        <Button onClick={handleExport} icon={<DownloadOutlined />}>
                            Export .CSV file
                        </Button>
                    </div>
                </div>

                {!isViewMode && (
                    <>
                        <Divider className="mb-2 mt-4" />
                        <Button
                            icon={<PlusCircleOutlined />}
                            type="text"
                            onClick={() => setIsAddPlayerModalVisible(true)}
                        >
                            Add Player
                        </Button>
                    </>
                )}
                <Divider className="mb-0 mt-2" />
                <Table
                    rowSelection={isViewMode ? undefined : rowSelection}
                    rowKey="playerId"
                    size="small"
                    columns={columns}
                    dataSource={players || []}
                    pagination={{
                        current: paginationData.pageNumber,
                        pageSize: paginationData.pageSize,
                        total: playersTotal,
                        showSizeChanger: true,
                        onChange: handlePagination,
                    }}
                    onChange={onTableChange}
                />
            </div>
        </>
    );
};

export default PlayersTab;
