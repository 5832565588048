import { localStorageHelper } from '../../../../utils';
import FormikField from './FormikField';
import { Divider } from 'antd';
import React from 'react';

const ConfigurationTab = () => {
    const timezoneList = localStorageHelper
        .getChainedValue('user.timezoneList')
        ?.map((value: any) => ({
            label: `UTC${value.offset} ${value.area}/${value.location}`.toUpperCase(),
            value: value.id,
        }));

    return (
        <div className="space-y-8">
            <div className="flex flex-col space-y-2">
                <FormikField
                    name="campaign_name"
                    category="input"
                    label="Campaign Name"
                    className="w-full max-w-sm"
                />
            </div>

            <Divider />

            <div className="flex flex-col space-y-2">
                <FormikField
                    name="timezone"
                    category="select"
                    label="Time Zone"
                    className="w-full max-w-sm"
                    options={timezoneList}
                />
            </div>

            <div className="flex items-center">
                <FormikField
                    name="start_at"
                    category="datePicker"
                    label="Start at"
                    className="w-full max-w-xs mr-4"
                />
                <FormikField
                    name="end_at"
                    category="datePicker"
                    label="End at"
                    className="w-full max-w-xs mr-4"
                />
            </div>

            <div className="flex items-center">
                <FormikField
                    name="valid_for"
                    category="input"
                    type="number"
                    label="Valid for (days)"
                    className="w-full max-w-xs mr-4"
                />
                <FormikField
                    name="start_event"
                    category="select"
                    label="Starts on"
                    className="w-full max-w-xs mr-4"
                    options={[
                        { value: 'ASSIGN', label: 'Player Added To The Campaign' },
                        { value: 'ACCEPT', label: 'Player Accepts The Campaign' },
                    ]}
                />
                <FormikField
                    name="allowPostExpirationCompletion"
                    category="switch"
                    label="Allow PostExpiration Completion"
                    className="w-full"
                />
            </div>

            <Divider />

            <div className="flex flex-col space-y-2">
                <FormikField
                    name="rules_url"
                    category="input"
                    label="Rules URL"
                    className="w-full max-w-sm"
                />
            </div>
        </div>
    );
};

export default ConfigurationTab;
